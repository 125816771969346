import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) },
  {
    path: 'peopledetail/:id',
    children:
      [
        {
          path: '',
          loadChildren: () => import('./Tab_People/people-detail/people-detail.module').then(m => m.PeopleDetailPageModule)
        }
      ]
  },
  {
    path: 'chat/:id',
    children:
      [
        {
          path: '',
          loadChildren: () => import('./messenger/chat/chat.module').then(m => m.ChatPageModule)
        }
      ],
    canActivate: [AuthGuard]
  },
  {
    path: 'detail/:id',
    children:
      [
        {
          path: '',
          loadChildren: () => import('./OtherPages/detail/detail.module').then(m => m.DetailPageModule)
        }
      ],
    canActivate: [AuthGuard]
  },
  { path: 'auth', loadChildren: () => import('./Tab_Setting/auth/auth.module').then(m => m.AuthPageModule) },
  {
    path: 'agreement',
    loadChildren: () => import('./Tab_Setting/agreement/agreement.module').then(m => m.AgreementPageModule)
  },
  {
    path: 'ratesave/:id/:user',
    loadChildren: () => import('./OtherPages/ratesave/ratesave.module').then(m => m.RatesavePageModule)
  },
  {
    path: 'fullsreen-image/:url',
    loadChildren: () => import('./OtherPages/fullsreen-image/fullsreen-image.module').then(m => m.FullsreenImagePageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard]
  },
  { path: 'start', loadChildren: () => import('./start/start.module').then(m => m.StartPageModule) },
  {
    path: 'edit/:id',
    loadChildren: () => import('./OtherPages/edit-ad/edit-ad.module').then(m => m.EditAdPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'question-detail',
    loadChildren: () => import('./question-detail/question-detail.module').then( m => m.QuestionDetailPageModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./company/company.module').then( m => m.CompanyModule)
  },
  { path: 'recover', loadChildren: () => import('./Tab_Setting/auth/recover/recover.module').then( m => m.RecoverPageModule) },
  { path: 'registration', loadChildren: () => import('./Tab_Setting/auth/registration/registration.module').then( m => m.RegistrationPageModule) },

  { path: 'wallet', loadChildren: () => import('./wallet/wallet.module').then( m => m.WalletModule), canActivate: [AuthGuard] },
  // { path: 'about', loadChildren: () => import('./about/about.module').then( m => m.AboutModule) }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
