export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyACF-9bTDCeO3I2gHQ-oQjxC_NHst7DH80",
    authDomain: "marketplace-79ab7.firebaseapp.com",
    databaseURL: 'https://marketplace-79ab7.firebaseio.com',
    projectId: "marketplace-79ab7",
    storageBucket: "marketplace-79ab7.appspot.com",
    messagingSenderId: "425690324690",
    appId: "1:425690324690:web:4add09b00fe42174b7d70b",
    measurementId: "G-QSB2SEG0FR",
    yandexMoney: ''
};
