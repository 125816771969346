import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export * from 'rxjs';

import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireDatabase, SnapshotAction, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { Storage } from '@ionic/storage-angular';
import { Params } from '@angular/router';

// tslint:disable-next-line:interface-over-type-literal
export declare type FirebaseData = { [key: string]: any };
export declare type ObservableFirebaseList = Observable<FirebaseData[]>;
export declare type ObservableFirebaseDetail = Observable<FirebaseData>;

@Injectable({
  providedIn: 'root'
})

export class CoreService {

  constructor(
    public toastController: ToastController,
    private translateService: TranslateService,
    public loadingController: LoadingController,
    public alertController: AlertController,

    private db: AngularFireDatabase,
    private storage: Storage,

  ) { }

  // Ограничение кол-ва символов в поле данных
  public static trimField(data: any, field: string, count: number) {
    if (data[field]) {
      if (data[field].length > count) {
        data[field] = data[field].slice(0, count) + '...';
      }
    }
  }

  public static snapshotObjectToModel(item: SnapshotAction<any> | any): { [key: string]: any } {
    const data = item.payload.val();
    return { key: item.payload.key, ...data };
  }

  public static snapshotListToModel(actions: SnapshotAction<any>[]): any[] {
    return actions.map(action =>
      this.snapshotObjectToModel(action)
    );
  }

  public static snapshotChangesListToModel(list: AngularFireList<any>): Observable<FirebaseData[]> {
    return list.snapshotChanges()
      .pipe(
        map(actions => CoreService.snapshotListToModel(actions))
      );
  }

  public static snapshotChangesObjectToModel(object: AngularFireObject<any>): Observable<FirebaseData> {
    return object.valueChanges()
      .pipe(
        map(item => item ? item : {})
      );
  }

  public trackByFn(index: number, item: FirebaseData) {
    return item.key;
  }

  async presentAlert(msg: string, subject?: string) {
    const alert = await this.alertController.create({
      header: subject || 'Ошибка',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  // информационное всплывающее сообщение на 2 секунды
  async presentToast(mess: string, color?: 'danger' | 'success') {
    this.translateService.get(mess).subscribe(async (res: string) => {
      const toast = await this.toastController.create({
        message: res,
        duration: 2000,
        color: color || 'danger'
      });
      toast.present();
    });
  }

  // Спиннер загрузки с сообщением
  async presentLoading(mess: string) {
    const loading = await this.loadingController.create({
      message: mess,
    });
    await loading.present();
  }

  // Закрытие спиннера
  async dismissLoading() {
    return await this.loadingController.dismiss();
  }


  // Загрузка видимости пунктов настроек из базы данных
  getSettings() {
    return this.db.object('setting/toggles').valueChanges();
  }

  // Загрузка видимости пунктов настроек из кэша
  getCacheSettings() {
    return this.storage.get('setting');
  }

  // Запись видимости пунктов настроек в кэш
  setCacheSettings(setting) {
    this.storage.set('setting', setting);
  }

  filterSearch(search$: BehaviorSubject<string>, list$: Observable<Params[]>, fields: string[]): Observable<Params[]> {
    return search$.asObservable()
      .pipe(
        switchMap(searchValue => {
          const searchValueLow = searchValue.toLocaleLowerCase();
          return list$
            .pipe(
              map(items =>
                items.filter(item =>
                  fields.map(field => item[field]).join(' ')
                    .toLocaleLowerCase().indexOf(searchValueLow) !== -1
                )
              )
            );
        })
      );
  }
}
