import { Component } from '@angular/core';
// import { Geolocation } from '@capacitor/geolocation';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor() {}

  ngOnInit() {
    // Geolocation.getCurrentPosition().then(coords => {
    //   alert('Current position:' + JSON.stringify(coords));
    // }).catch(e => alert(JSON.stringify(e)));
  }
}
